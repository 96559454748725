import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Criterion, SearchItemModel } from 'src/app/core/services/base-event-service';

import { AppButtonSize, AppButtonType } from 'src/app/shared/shared.model';
import { getFiltersFormModel } from 'src/app/core/utils/filter.utils';
import { debounceTime, filter, tap } from 'rxjs';
import { isPresent } from 'src/app/core/utils/isPresent';
import { selectCounterparties } from '../../store/selectors';
import { CounterpartiesActions } from '../../store/actions';
import { CounterpartySearchModel } from '../../models/counterparties.model';
import { getCounterpartiesFilterConditionsFromModel } from 'src/app/core/utils/parties.utils';
import { CounterpartySearchCriterionsFactory } from 'src/app/core/factories/counterparty-search-criterions.factory';
import { CounterpartiesListEventsService } from '../counterparties-list/counterparties-list-events.service';
import { AddCounterpartyModalComponent } from '../modals/add-counterparty-modal/add-doc-type-modal.component';
import { selectUser } from 'src/app/core/store/selectors';

@UntilDestroy()
@Component({
  selector: 'app-counterparties',
  templateUrl: './counterparties.component.html',
  styleUrl: './counterparties.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CounterpartiesComponent implements OnInit {
  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;
  public criterions: Criterion[] = this.counterpartySearchCriterionsFactory.getAllItemsAsArray();
  public conditions: SearchItemModel[] | undefined;
  public form: FormGroup | undefined;
  public dataRecieved: boolean = false;

  constructor(
    private readonly store$: Store,
    private titleService: Title,
    private bsModalService: BsModalService,
    private counterpartiesListEventsService: CounterpartiesListEventsService,
    private counterpartySearchCriterionsFactory: CounterpartySearchCriterionsFactory,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.waitUserReadyAndStart();
  }

  waitUserReadyAndStart(): void {
    this.store$
      .select(selectUser)
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe((user) => {
        this.startInitialization();
      });
  }

  startInitialization(): void {
    this.store$.dispatch(CounterpartiesActions.getCounterparties());

    this.initForm();

    this.store$
      .select(selectCounterparties)
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe((data) => {
        this.dataRecieved = !!data;
        this.cdr.markForCheck();
        setTimeout(() => {
          this.checkAndRestoreFilters();
        }, 1);
      });

    this.titleService.setTitle('AutoLex | Counterparties');
    this.counterpartiesListEventsService.setFilterCriterions(this.criterions);
  }

  checkAndRestoreFilters() {
    const filters = this.counterpartiesListEventsService.getFilters();

    if (filters?.length > 0) {
      const model = getFiltersFormModel(filters);
      this.form?.patchValue(model);
    } else {
      this.counterpartiesListEventsService.onFilter([]);
    }
  }

  initForm() {
    this.form = this.fb.group({
      counterpartyName: [''],
    });

    this.initSubscriptions();
    this.cdr.markForCheck();
  }

  initSubscriptions(): void {
    this.form?.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(50),
        tap((model: CounterpartySearchModel) => {
          this.conditions = getCounterpartiesFilterConditionsFromModel(
            model,
            this.counterpartySearchCriterionsFactory,
          );
        }),
      )
      .subscribe((value) => {
        this.counterpartiesListEventsService.resetPagination();

        if (!!this.conditions) {
          this.counterpartiesListEventsService.onFilter(this.conditions || []);
        }
      });
  }

  addCounterparty(): void {
    this.bsModalService.show(AddCounterpartyModalComponent, {
      initialState: {},
      class: 'modal-lg',
    });
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SharedState } from './state';

export const SharedFeature = 'SharedFeature';

export const sharedFeatureStateSelector = createFeatureSelector<SharedState>(SharedFeature);

export const selectContentLanguages = createSelector(
  sharedFeatureStateSelector,
  (state: SharedState) =>
    state.contentLanguages
      ? [...state.contentLanguages].sort((a, b) => a.displayName.localeCompare(b.displayName))
      : [],
);

export const selectInterfaceLanguages = createSelector(
  sharedFeatureStateSelector,
  (state: SharedState) =>
    state.interfaceLanguages
      ? [...state.interfaceLanguages].sort((a, b) => a.displayName.localeCompare(b.displayName))
      : [],
);

export const fromShared = {
  selectContentLanguages,
  selectInterfaceLanguages,
};

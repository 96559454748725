import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { combineLatest, debounceTime, filter, tap } from 'rxjs';
import { QuestionsSearchCriterionsFactory } from 'src/app/core/factories/questions-search-criterions.factory';
import { Criterion, SearchItemModel } from 'src/app/core/services/base-event-service';
import { getFiltersFormModel } from 'src/app/core/utils/filter.utils';
import { isPresent } from 'src/app/core/utils/isPresent';
import { getQuestionsFilterConditionsFromModel } from 'src/app/core/utils/questions.utils';
import { selectDocTypes } from 'src/app/modules/doc-types/store/selectors';
import { PositionsApiService } from 'src/app/shared/services/positions-api.service';
import { AppButtonSize, AppButtonType } from 'src/app/shared/shared.model';
import { selectContentLanguages } from 'src/app/shared/store/selectors';
import { QuestionsSearchModel } from '../../models/questions.model';
import { QuestionsActions } from '../../store/actions';
import { fromQuestions } from '../../store/selectors';
import { AddQuestionModalComponent } from '../modals/add-question-modal/add-question-modal.component';
import { QuestionsListEventsService } from '../questions-list/questions-list-events.service';
import { selectUser } from 'src/app/core/store/selectors';

@UntilDestroy()
@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrl: './questions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionsComponent implements OnInit {
  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;
  public criterions: Criterion[] = this.questionsSearchCriterionsFactory.getAllItemsAsArray();
  public conditions: SearchItemModel[] | undefined;
  public docTypeItems: any;
  public positionItems: any;
  public form: FormGroup | undefined;
  public contentLanguages: any[] = [];
  public dataRecieved: boolean = false;

  constructor(
    private readonly store$: Store,
    private titleService: Title,
    private bsModalService: BsModalService,
    private questionsListEventsService: QuestionsListEventsService,
    private questionsSearchCriterionsFactory: QuestionsSearchCriterionsFactory,
    private readonly positionsApiService: PositionsApiService,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.waitUserReadyAndStart();
  }

  waitUserReadyAndStart(): void {
    this.store$
      .select(selectUser)
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe((user) => {
        this.startInitialization();
      });
  }

  startInitialization(): void {
    this.store$.dispatch(QuestionsActions.getQuestions());

    this.initForm();

    this.store$
      .select(fromQuestions.selectQuestions)
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe((data) => {
        this.dataRecieved = !!data;
        this.cdr.markForCheck();
        setTimeout(() => {
          this.checkAndRestoreFilters();
        }, 1);
      });

    this.titleService.setTitle('AutoLex | Questions');
    this.questionsListEventsService.setFilterCriterions(this.criterions);
  }

  checkAndRestoreFilters() {
    const filters = this.questionsListEventsService.getFilters();

    if (filters?.length > 0) {
      const model = getFiltersFormModel(filters);
      this.form?.patchValue(model);
    } else {
      this.questionsListEventsService.onFilter([]);
    }
  }

  initForm() {
    combineLatest([
      this.store$.select(selectDocTypes).pipe(filter(isPresent)),
      this.positionsApiService.getPositions$(),
      this.store$.select(selectContentLanguages).pipe(filter(isPresent)),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([docTypes, postitions, contentLanguages]) => {
        this.docTypeItems = docTypes;
        this.positionItems = postitions;
        this.contentLanguages = contentLanguages;

        this.form = this.fb.group({
          questionName: [''],
          documentType: [null],
          position: [null],
          languageId: [null],
        });

        this.initSubscriptions();
        this.cdr.markForCheck();
      });
  }

  initSubscriptions(): void {
    this.form?.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(50),
        tap((model: QuestionsSearchModel) => {
          this.conditions = getQuestionsFilterConditionsFromModel(
            model,
            this.questionsSearchCriterionsFactory,
          );
        }),
      )
      .subscribe((value) => {
        this.questionsListEventsService.resetPagination();

        if (!!this.conditions) {
          this.questionsListEventsService.onFilter(this.conditions || []);
        }
      });
  }

  clearFilters(): void {
    this.form?.reset();
    this.cdr.markForCheck();
  }

  addQuestion(): void {
    this.bsModalService.show(AddQuestionModalComponent, {
      initialState: {},
      class: 'modal-xl',
    });
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LocalizationState } from './state';

export const localizationFeatureKey = 'localizationFeature';

export const selectFeatureState = createFeatureSelector<LocalizationState>(localizationFeatureKey);

export const selectActiveLang = createSelector(
  selectFeatureState,
  (state: LocalizationState) => state.activeLang,
);

export const selectIsRtl = createSelector(selectFeatureState, (state: LocalizationState) =>
  state.activeLang ? state.activeLang === 'ar' : null,
);

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { LocalizationActions } from 'src/app/modules/localization/store/actions';
import { LocalizationState } from 'src/app/modules/localization/store/state';
import { AppState } from '../../store/state';
import { AppButtonSize, AppButtonType } from 'src/app/shared/shared.model';
import { LanguageDto } from 'src/app/shared/services/languages-api.service';
import { combineLatest, filter } from 'rxjs';
import { isPresent } from '../../utils/isPresent';
import { selectInterfaceLanguages } from 'src/app/shared/store/selectors';
import { PostUserDto, UserDto } from 'src/app/modules/login/services/user-api.service';
import { GetUserActions } from '../../store/actions';
import { selectAuthenticatedAccount, selectUser } from '../../store/selectors';
import { Router } from '@angular/router';
import { AuthGreenService, AuthType } from 'src/app/modules/login/auth-green.service';
import { INavigationState } from 'src/app/modules/login/components/login.component';
import { ApplicationPaths } from 'src/app/modules/login/api-authorization.constants';

@UntilDestroy()
@Component({
  selector: 'app-left-side-menu',
  templateUrl: './left-side-menu.component.html',
  styleUrl: './left-side-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftSideMenuComponent implements OnInit {
  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;
  public isAuthenticatedAccount$ = this.store$.select(selectAuthenticatedAccount);
  public user: UserDto | undefined;
  public interfaceLanguages: LanguageDto[] = [];
  public isUser$ = this.store$.select(selectUser);
  public greenAuthUser: any;
  public availableLangs = this.translocoService.getAvailableLangs() as string[];
  public langBtnMenuItems: any[] = [];

  constructor(
    public router: Router,
    private store$: Store<AppState>,
    private authService: MsalService,
    private authGreenService: AuthGreenService,
    public translocoService: TranslocoService,
    private localizationStore: Store<LocalizationState>,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.store$.select(selectUser).pipe(untilDestroyed(this), filter(isPresent)),
      this.store$.select(selectInterfaceLanguages).pipe(untilDestroyed(this), filter(isPresent)),
    ])
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe(([user, interfaceLanguages]) => {
        this.user = user;
        this.interfaceLanguages = interfaceLanguages;
        this.initLanguageSwitcher();
      });
  }

  initLanguageSwitcher(): void {
    this.langBtnMenuItems = this.availableLangs.map((lang) => ({
      label: this.translocoService.translate('language.' + lang),
      icon: `flag-option flag-${lang}`,
      command: () => this.changeLang(lang),
    }));
  }

  onMenuClick(step: number): void {
    this.router.navigate(['/documents'], { replaceUrl: false, skipLocationChange: false });
  }

  logout(popup?: boolean) {
    const authType = localStorage.getItem('AUTH_TYPE') || '';

    if (+authType === AuthType.EMAIL) {
      const state: INavigationState = { returnUrl: ApplicationPaths.LoggedOut };
      this.authGreenService.signOut(state);
    } else {
      if (popup) {
        this.authService.logoutPopup({
          mainWindowRedirectUri: '/login',
        });
      } else {
        this.authService.logoutRedirect();
      }
    }
  }

  get activeLang() {
    return this.translocoService.getActiveLang();
  }

  changeLang(selectedLangName: any) {
    this.localizationStore.dispatch(LocalizationActions.setLang({ lang: selectedLangName }));

    const selectedLang = this.interfaceLanguages.find((lang: LanguageDto) => {
      return lang.name === selectedLangName;
    });

    if (selectedLang) {
      const user = { ...this.user } as PostUserDto;
      user.defaultInterfaceLanguageId = selectedLang.id;
      this.store$.dispatch(GetUserActions.saveUserDetails({ user }));
    }
  }
}

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { map, mergeMap, switchMap, take, tap } from 'rxjs';
import { LocalizationActions } from './actions';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class FeatureEffects {
  constructor(
    private readonly actions$: Actions,
    public translocoService: TranslocoService,
  ) {}

  setLang$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocalizationActions.setLang),
      switchMap((props) =>
        this.translocoService
          .load(props.lang)
          .pipe(take(1))
          .pipe(map(() => LocalizationActions.setLangSuccess(props))),
      ),
    ),
  );

  setLangSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LocalizationActions.setLangSuccess),
        tap((props) => {
          this.translocoService.setActiveLang(props.lang);
          document.documentElement.dir = props.lang === 'ar' ? 'rtl' : 'ltr';
          localStorage.setItem('preferLang', props.lang);
        }),
      ),
    { dispatch: false },
  );
}

<mat-nav-list>
  <img class="logo" src="./../../../../assets/logo.svg" alt="logo" />
  <mat-list-item
    (click)="onMenuClick(1)"
    routerLink="documents"
    [routerLinkActive]="['active-link']"
  >
    <span class="menu-item">
      <span class="material-symbols-rounded"> note_stack </span>
      <a class="full-width" href="javascript:void(0);"> {{ 'menu.myDocuments' | transloco }}</a>
    </span>
  </mat-list-item>
  <mat-list-item routerLink="/playbook" [routerLinkActive]="['active-link']">
    <span class="menu-item">
      <span class="material-symbols-rounded"> menu_book </span>
      <a class="full-width" href="javascript:void(0);"> {{ 'menu.playbooks' | transloco }}</a>
    </span>
  </mat-list-item>
  <mat-list-item routerLink="questions" [routerLinkActive]="['active-link']">
    <span class="menu-item">
      <span class="material-symbols-rounded"> help </span>
      <a class="full-width" href="javascript:void(0);"> {{ 'menu.keyQuestions' | transloco }} </a>
    </span>
  </mat-list-item>
  <mat-list-item routerLink="document-types" [routerLinkActive]="['active-link']">
    <span class="menu-item">
      <span class="material-symbols-rounded"> stacks </span>
      <a class="full-width" href="javascript:void(0);"> {{ 'menu.docTypes' | transloco }} </a>
    </span>
  </mat-list-item>
  <mat-list-item routerLink="counterparties" [routerLinkActive]="['active-link']">
    <span class="menu-item">
      <span class="material-symbols-rounded"> view_cozy </span>
      <a class="full-width" href="javascript:void(0);"> {{ 'menu.counterparties' | transloco }} </a>
    </span>
  </mat-list-item>
  <mat-list-item routerLink="baseline-documents" [routerLinkActive]="['active-link']">
    <span class="menu-item">
      <span class="material-symbols-rounded"> cloud_upload </span>
      <a class="full-width" href="javascript:void(0);">
        {{ 'menu.baselineDocuments' | transloco }}
      </a>
    </span>
  </mat-list-item>
  <mat-list-item routerLink="profile" [routerLinkActive]="['active-link']">
    <span class="menu-item">
      <span class="material-symbols-rounded"> person </span>
      <a class="full-width" href="javascript:void(0);"> {{ 'menu.profile' | transloco }} </a>
    </span>
  </mat-list-item>

  <div class="languages" *ngIf="activeLang">
    <p-splitButton class="split-btn" [model]="langBtnMenuItems">
      <ng-template pTemplate="content">
        <span class="flex items-center font-bold">
          <span class="material-symbols-rounded globe"> language </span>
          <span>{{ 'language.' + activeLang | transloco }}</span>
        </span>
      </ng-template>
    </p-splitButton>
  </div>

  <div class="auth" *let="isUser$ | async as user">
    <p class="acc-name">{{ user?.name }}</p>
    <app-button
      *ngIf="user || greenAuthUser"
      icon="logout"
      [type]="btnTypes.Stroked"
      [size]="btnSizes.FullWidth"
      [label]="'buttons.logout' | transloco"
      (onClick)="logout()"
    ></app-button>
  </div>
</mat-nav-list>

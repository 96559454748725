import { createReducer, on } from '@ngrx/store';
import { initialState } from './state';
import { LocalizationActions } from './actions';

export const featureReducer = createReducer(
  initialState,
  on(LocalizationActions.setLang, (state, { lang }) => ({
    ...state,
    activeLang: lang,
  })),
);
